.member{
display: flex;
flex-direction: column;
}
.member img{
    height:500px;
    object-fit: cover;
   }
.member-info{
    text-align: left;
    padding: 15px 20px 46px;
    background-color: #C4C4C4;
}
.member-info .name{
font-size: 30px;
font-weight: 700;
line-height: 130%; /* 39px */
color:var(--primary)
}

@media screen and (max-width: 900px) { 
    .member {
        width:70%;
        margin:0 auto;
    }       
}
@media screen and (max-width: 764px) { 
    .member {
        width:100%;
    }       
}
    
 