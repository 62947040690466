.section-header{
    text-align: center;
    padding-top:100px;
    
}
.section-header-title{
color: var(--primary);
width:508px;
margin:0 auto;
font-size: 46px;
font-weight: 700;
line-height: 130%; /* 62.4px */
margin-bottom:16px;
}
.section-header-desc{
    line-height: 140%; /* 25.2px */
    width:600px;
    margin: 0 auto;
    padding-bottom:80px;
}
@media screen and (max-width: 900px) {
    .section-header-title, .section-header-desc {
        width:auto;
    }
    .section-header-desc {
        padding-bottom: 40px;
    }
}