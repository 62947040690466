.subpage-hero{
    width:100%; 
    height:250px;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../assets/img/conference.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.subpage-hero .title{
 color:#fff;
 font-size: 48px;
 text-align: center;
 padding-top: 120px;
}
@media screen and (max-width: 900px) {
    .subpage-hero{
        height:200px;
    }
    .subpage-hero .title{
        font-size: 32px;
}
}