/* Barlow Condensed */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/BarlowCondensed-Regular.woff2') format('woff2'); 
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/BarlowCondensed-Bold.woff2') format('woff2'); 
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/BarlowCondensed-Bold.woff2') format('woff2'); 
}
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Righteous-Regular.woff2') format('woff2'); 
}

body, html {
  font-family: 'Barlow Condensed';
  font-size: 18px;
}
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
p{margin:10px 0;}

h1, h2, h3, h4, h5, h6 {
  margin:0
}

:root {
  --primary: #92298d;
  --secondary: #9C3E98;
  --textcolor: #555555;
  --background: #363636;
  --textcolor2: #3B3B3B;
}

.container{
  width:1240px;
  margin:0 auto;
}
.text-center{
  text-align: center;
}
.btn{
  background-color: var(--primary);
  text-align:center;
  font-size: 22px;
  font-weight: normal;
  line-height: 26px;
  padding:12px 17px;
  border:none;
  color:#fff;
  transition: all .3s ease;
  cursor: pointer;
}
.btn:hover{
  background-color: var(--secondary);
}

.link{
  text-decoration:none;
  color:inherit;
}

@media screen and (max-width: 1320px) {
  .container{
    width:1024px;
  }
}
@media screen and (max-width: 1080px) {
  .container{
    width:840px;
  }
}
@media screen and (max-width: 900px) {
  .container{
    width:680px;
  }
}
@media screen and (max-width: 764px) {
  .container{
    width:580px;
  }
}
@media screen and (max-width: 600px) {
  .container{
    width:84%;
    margin: 0 auto;
  }
}
