.hero{
    width:100%; 
    height:720px;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../assets/img/conference.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.hero-info{
    padding-top:183px; 
}
.hero-title{
width: 444px;
color: #FFF;
font-size: 72px;
font-weight: 700;
line-height: 70px; /* 97.222% */
margin-bottom:30px;
}
.hero-content{
    color: #FFF;  
    font-size: 18px;
    line-height: 140%;
    width:451px;
}
.hero-timer{
    display: flex;
    gap:17px;
}
 
.hero-timer-item{
    background-color: #fff;
    width:100px;
    height:100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
 
}
.hero-timer-item:hover{
scale: 1.02;
}

.hero-timer-item p{
    font-size: 41px;
    font-weight: 500;
    color:var(--textcolor2);
    margin:0;
}
.hero-timer-item span{
    font-size: 18px;
    font-weight: 500;
    color:var(--textcolor2);
}

.hero-btn{
    margin-top:20px;
}

@media screen and (max-width: 764px) {
    .hero{
      text-align: center;
      margin-top:0; 
    }
    .hero-title{
        width:auto;
        font-size: 48px;
        line-height: 48px;
        margin: 0;
    }
    .hero-content{
        width:auto;
        margin-bottom: 40px;
    }
    .hero-timer{
        justify-content: center;
        margin-bottom: 40px;
        gap:6px;
         
    }
    
    .hero-timer-item p{
        font-size: 22px;
        margin-bottom:10px;
    }
    .hero-timer-item span{
        font-size: 14px;
    }
  }