.testimonial{
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 5fr 7fr;
}

.testimonial-img{
    background-image: url('../../assets/img/venue.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
}

.testimonial-content .container{
    width:100%;
 }
 .testimonial-content .section-header{
    padding-top: 0px;
    text-align: right;
  }
  .testimonial-content .section-header-desc {
    display:none;
  }

 .testimonial-content .section-header-title{
    width:400px;
    margin:50px 50px 0 auto;
  }
  .testimonial-content .testimonial-box{
    width:560px;  
    margin:50px 50px 50px auto;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap:2rem;
    background-color: var(--primary);
    color:#fff;
  }
  .testimonial-content {
    min-height: 100px;
  }
.testimonial-name{
  font-size:26px;
  font-weight: 500;
}
.testimonial-arrows{
  width:560px;
  margin:50px 50px 50px auto;
  display: flex;
  justify-content: center;
  gap:1rem;
  }

  @media screen and (max-width: 1320px) {
    .testimonial{
      grid-template-columns: 4fr 8fr;
  }
  .testimonial-content .testimonial-box{
    margin-right: 0;
  }
  .testimonial-arrows{
    margin: 0 0 0 auto;
  }
  }
  @media screen and (max-width: 1080px) {
    .testimonial{
     gap: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    .testimonial{
      grid-template-columns: 100%;
  }
    .testimonial-img{
      display: none;
  }
  .testimonial-content {
    width:100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 2rem;
    min-height: 135px;

  }
  .testimonial-content .section-header-title{
    width:90%;
    margin:0 auto;
    text-align: center;
  }
  .testimonial-content .testimonial-box{
    width:90%;
    margin:0 auto;
  }
  .testimonial-arrows {
    margin: 0 auto;
    width:100%;
  }

  }

