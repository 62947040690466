.nav-row{
    position: fixed;
    width:100%;
    z-index:999;
}
.white{
    background-color: #fff;
}
.white .nav-menu li:not(.btn){
    color:var(--textcolor)
}
.nav{
    height:80px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
     transition: .3s;
}
.pt-0{
    padding-top:0;
}

.nav-menu-mobile{
   display:none;
}
.nav-mobile-icon{
    display: none;
}
.nav-menu{
    display:flex;
    flex-direction: row;
    gap:3rem;
    align-items: center;
}
.nav-menu li{
 list-style-type: none;
 color:#fff;
 font-size: 20px;
 font-weight: 500;
 cursor: pointer;
}
.logo{
    font-family: 'Righteous';
    font-size: 36px;
    color:#fff;
    cursor: pointer;
}
.black{
    color:var(--textcolor);
}
.logo::first-letter {
    color:var(--primary)
}


@media screen and (max-width: 900px) {
    .nav-mobile-icon{
      display: block;
      font-size:24px;   
    }
    .nav-menu{
        display: none;
    }
    .nav-menu-mobile{
        display: block;
        background-color: #fff;
        padding: 0;
        text-align: center;
        width:80%;
        position: absolute;
        top:0px;
        right:-100%;
        transition: .3s;
        cursor: pointer;
    }
    .mobile-close{
        background-color: var(--primary);
    }
    .mobile-close:first-child{
        color:#fff;
    }
  .active{
      right:0;
    }
    .nav-menu-mobile li{
        list-style-type: none; 
        color:var(--textcolor2);
        font-size:22px;
        padding:20px;
        border-bottom:1px solid var(--textcolor2);
        transition: .3s;
    }
    .nav-menu-mobile li:hover{
        background-color:var(--primary);
        color:#fff;
    }
    .nav-menu-mobile .btn{
        background-color: #fff;
    }
  }