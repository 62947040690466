.video{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url('../../assets/img/video.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    position: relative;
    padding-bottom: 80px;
    padding-top:40px;
}
.video .section-header-desc{
color:#fff;
padding-bottom: 50px;
}
.video img{
    padding-bottom: 80px;
    cursor: pointer;
    }
.video-modal{
 position:absolute;
 top:50%;
 left: 50%;
 transform: translate(-50%,-50%);
}
.video-frame{
    position: relative;
    width: fit-content; 
    margin: 0 auto;
}
.video-close{
    position: absolute;
    top: -25px;
    right: -25px;
    color:#fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}
.video-frame iframe{
     background-color: #fff;
     padding:2px;
     border:none;
}

#player{
    width:800px;
    height: 450px;
}

@media screen and (max-width: 900px) {
    #player{
        width:600px;
        height: 338px;
    }
}

@media screen and (max-width: 764px) {
    #player{
        width:450px;
        height: 253px;
    }
}
@media screen and (max-width: 600px) {
    #player{
        width:auto;
        height: auto;
    }
    .video-close{
        top: -30px;
        right: 0;
    }
}
