.form-section{
    background-color: var(--background);
 
}
.form-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top:-130px;
    position: relative;
    z-index: 9;
}
.form-box .form-info{
    background-color: var(--primary);
    color:#fff;
    padding-top:50px;
    padding-left:50px;
}
.form-box .form-info-title{
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 36px;
}
.form-box .form-info-content{
    border-left:2px solid #fff;
    padding-left:20px;
    margin-bottom: 36px;
}
.form-box .form{
    padding:50px;
    background-color: var(--secondary);
    display:flex;
    flex-direction: column;
}
.form-group{
    display: flex;
    flex-direction: column;
    gap:6px;
    margin-bottom: 20px;
   }
.form-box .form input, .form-box .form textarea {
   padding: 15px;
}

.form-box .form label{
  color:#fff;
  margin-bottom: 4px;
 }

 .form-box .form .form-btn{
    background-color: #fff;
    color:var(--primary);
    border:none;
    margin-top:40px;
    width:fit-content;
    align-self: end;
    transition: .3s;
   }
   .form-box .form .form-btn:hover{
    background-color: var(--primary);
    color:#fff;
   }
   .form-error{
    color:rgb(195, 205, 6);
   }

   .acceptTerms{
    display: flex;
    align-items: center;
    gap: 1rem;
   }
   .form .acceptTerms label, .form .acceptTerms input{
   margin-bottom: 0;
   }

   @media screen and (max-width: 900px) {
    .form-box{
        display: grid;
        grid-template-columns: 1fr;
        margin-top:0px;
        padding-top: 60px;
    }
   }