.venue{
    margin:80px auto;
    display: grid;
    grid-template-columns: 7fr 5fr;
}
.venue-content{
    background-color: var(--primary);
    color:#fff;
    padding-bottom: 100px;
}
.venue-content .container{
   width:100%;
}
.venue-content .section-header{
    padding-top: 50px;
    text-align: left;
 }
.venue-content .section-header-title{
   color:#fff;
   width:400px;
   margin:0 auto 20px 10% ;
 }
 .venue-content .section-header-desc{
    width:360px;
    margin:0 10%;
  }

.venue-location, .venue-accommodation{
    width:80%;
    margin:0 auto;
    display: flex;
    gap:2rem;
}
.venue-icon{
font-size: 65px;
}
.venue-img{
    background-image: url('../../assets/img/venue.webp');
    background-repeat: no-repeat;
    background-size: cover;
 
}
@media screen and (max-width: 900px) {
    .venue{
        grid-template-columns:1fr;
    
    }
    .venue-content {
        padding-bottom:50px;
      }
    .venue-content .section-header-title{
        width:85%;
      }
    .venue-content .section-header-desc{
         width:80%;
       }
    .venue-img{
        height:400px;
    }
    .venue-location, .venue-accommodation{
        flex-direction: column;
        gap:0;
        margin-bottom:20px;
    }

}