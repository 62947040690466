.sponsor{
    color:#fff;
    background-color: var(--primary);
    padding-bottom:80px;
}
.sponsor .section-header-title{
    color:#fff;
}
.sponsor-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);   
}
.sponsor-list .item{
    width:100%;
    text-align: center;
    border:1px solid #E486DF;
    padding:30px 0;
}
.sponsor-list .item:nth-child(-n+4){
    border-top:none;
}
.sponsor-list .item:nth-child(-n+8):not(:nth-child(-n+4)){
    border-bottom:none;
}
.sponsor-list .item:nth-child(-4n+5){
    border-left:none;
}
.sponsor-list .item:nth-child(-4n+8){
    border-right:none;
}

@media screen and (max-width: 900px) {
    .sponsor-list{
        grid-template-columns: repeat(2, 1fr);   
    }
    .sponsor-list .item:nth-child(-2n+7){
        border-left:none;
    }
    .sponsor-list .item:nth-child(-2n+8){
        border-right:none;
    }
  }

  @media screen and (max-width: 520px) {
    .sponsor-list{
        grid-template-columns: 1fr;   
    }
    .sponsor-list .item:nth-child(-2n+7){
        border-right:none;
    }
    .sponsor-list .item:nth-child(-2n+8){
        border-left:none;
    }
  }
