.schedule{
    background: linear-gradient(rgb(146, 41, 141, 0.6), rgb(146, 41, 141, .8)),url('../../assets/img/video.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    margin-top: 120px;
    color:#fff;
    padding-bottom: 80px;
}
.schedule .section-header-title{
    color:#fff;
}
.day-tabs{
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    text-align: center;
}
.day-tabs div{
    border:2px solid #fff;
    padding:1rem 5rem;
    cursor: pointer;
}
.schedule-items{
    display:flex;
    flex-direction: column;
}
.schedule-item{
    display:grid;
    grid-template-columns: auto 1fr 3fr;
    margin-bottom:20px;
    gap:0 5rem;
}
.schedule-item:not(:last-child):after{
    content:'';
    margin-top: 20px;
    margin-left:15px;
    width: 4px;
    height: 50px;
    background-color:#fff;
}
.schedule-date{
    display: flex;
    flex-direction: column;
    align-items: center;  
}
.schedule-day{
    font-size: 40px;
    font-weight: 700;
    margin-top: -7px;
}
.schedule-month, .schedule-name{
    font-size: 24px;
    font-weight: 500;
}
.schedule-name{
margin-bottom: 17px;
}
.schedule-speaker{
  font-weight: 500;
}

@media screen and (max-width: 900px) {
    .schedule-item{
        display:grid;
        grid-template-columns: 1fr;
        margin-bottom:20px;
        gap:1rem 0;
    }
  
    .schedule-item:not(:last-child):after{
        content:'';
        margin: 10px auto 40px;
        width: 60%;
        height: 4px;
        background-color:#fff;
    }
    .schedule-date{
       border: 2px solid #fff; 
       width:20%; 
       padding:0.5rem;
       margin:0 auto;
    }
    .day-tabs{
        flex-direction: column;
        gap:1rem;
       
    }
    
  }

