.members{
    background: #F1F1F1;
    text-align: center;
}
 
.members-cards{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap:2rem;
    position: relative;
    max-width: 100%;
}
.members-btn{
    margin: 80px 0 100px;
}

.members-paginate{
    
    padding:80px 0 100px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    
}

.members-paginate a {
    padding: 12px 16px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid var(--primary);
    color:var(--primary);
    cursor: pointer;
  }

  .members-paginate a:hover, .members-paginate .active a {
    background-color: var(--primary);
    color:#fff
  }

  @media screen and (max-width: 1080px) { 
    .members-cards{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
  }

  @media screen and (max-width: 900px) { 
    .members-paginate li:not(.previous, .next){
        display: none;
    }
    .members-cards{
        grid-template-columns: minmax(0, 1fr);
    }
  
  }