 
.footer-menu{
    background-color: var(--background);
    padding: 80px 0;
}
.footer-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
   
}
.footer-nav li {
  list-style-type: none;
  color:#fff;}

  .scroll-top{
    width:60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right:60px;
    bottom:60px;
    z-index: 999;
    background-color: var(--primary);
    padding:1rem;
    color:#fff;
  }

  @media screen and (max-width: 900px) {.footer-menu{
    padding: 40px 0;
}
    .footer-nav{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
  }