.about{
    background-image: url('../../assets/img/about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom:100px;
}
.about-box{
    display:grid;
   
}
.about-img{
    position:relative;
  
}
.about-img .img4{
    position:absolute;
    top:10%;
    left:30%;
    z-index:2 
}
.about-img .img3{
    position:absolute;
    top:60%;
    left:5%; 
    z-index:3   
}
.about-img .img2{
    position:absolute;
    top:-5%;
    left:0%;  
    z-index:1 
}
.about-img .img1{
    position:absolute;
    top:25%;
    left:70%; 
    z-index:3   
}
.about-img .el1{
    position:absolute;
    top:-15%;
    left:-10%; 
    z-index:3   
}
.about-img .el2{
    position:absolute;
    top:90%;
    left:40%; 
    z-index:3;  
}
.about-text{
    line-height: 140%;
}
.big-letter:first-letter{
    font-size: 48px;
    font-weight: 700;
    color:var(--textcolor)
}
.about-btn{
    margin-top:40px;
    margin-bottom:80px;
}
.about-pdecor{
    border-left:6px solid var(--primary);
    padding-left:10px;
}

@media screen and (min-width: 1081px) {
    .about-box{
        grid-template-columns:1fr 1fr;  
    }
  }
@media screen and (max-width: 1080px) {
    .about{
        padding-bottom:0;
    }
    .about-img{
        height:600px; 
    }
    .about-img .img4 {
        top: 50%;
        left: 50%;
        transform: translate(-10%, -50%);
      }
      .about-img .img2 {
        top: 0%;
        left: 20%;
      }
      .about-img .img3 {
        top: 60%;
        left: 5%;
      }
      .about-img .el1 {
        top: -10%;
        left: 8%;
      }
      .about-img .el2 {
        top: 75%;
        left: 75%;
      }
  }

  @media screen and (max-width: 764px) {
  
    .about-img .img4 {
        
        transform: translate(-40%, -50%);
      }
      .about-img .img2 {
        top: -5%;
        left: 10%;
      }
      .about-img .el2 {
        display: none;
      }
  }

  @media screen and (max-width: 600px) {
    .about-img{
        height:380px; 
    }
    .about-img .img4 {
        transform: translate(-50%, -50%);
      }
      .about-img .img2, .about-img .img3 {
       display: none;
      }
  }