.schedule-page .schedule{
background: #fff;
color:var(--textcolor);
margin-top:0;
}
.schedule-page .section-header-title{
    color:var(--primary)
}
.schedule-page .schedule-item:not(:last-child)::after {
    background-color: var(--primary);
}
.schedule-page .schedule-name{
    color:var(--primary)
}

