.speaker .section-header-desc{
    font-size:20px;
    font-weight: 500;
}
.speaker-bio{
    display: flex;
    gap: 1rem;
    margin-bottom: 60px;
}
.other-speakers{
    margin-bottom:80px;
}
.other-speakers h2{
    width:300px;
    margin:40px auto;
    padding: 10px 0;
    border-top: 2px solid var(--primary);
    border-bottom: 2px solid var(--primary);
    text-align: center;
}
.other-speakers-item{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap:1rem;
}
 
@media screen and (max-width: 1080px) {
    .other-speakers-item{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

@media screen and (max-width: 900px) {
    .other-speakers-item .member{
       width:100%;
    }
    .speaker-bio{
        flex-direction:column;
        margin-bottom: 40px;
    }
    .speaker-bio img{
        margin: 0 auto;
    }
  }

  @media screen and (max-width: 764px) {
    .other-speakers-item{
        grid-template-columns: minmax(0, 1fr);
    }
    .other-speakers-item .member{
        width:70%;
     }
     .other-speakers{
        text-align: center;
     }
  }
  @media screen and (max-width: 600px) {
    .other-speakers-item .member{
        width:100%;
     }
  }